import React from 'react';
import { NavLink } from 'react-router-dom';
import MobileDevice from './Mobile';
import XSVG from './XSVG';

export default function Header() {
  return (
    <div className="container mx-auto px-12">
      <div className="flex">
        <div className="w-full lg:w-1/2 py-4">
          <NavLink to={'/'}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="170"
              height="44.497"
              viewBox="0 0 170 44.497"
            >
              <g id="logo" transform="translate(0 1.073)">
                <g id="Group_2" data-name="Group 2">
                  <g id="Group_87" data-name="Group 87">
                    <text
                      id="BY_NOETIC_"
                      data-name="BY NOETIC "
                      transform="translate(77.069 40.553)"
                      fill="#0b276f"
                      fontSize="8.87"
                      fontFamily="ZonaPro-Bold, Zona Pro"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0" letterSpacing="-0.069em">
                        B
                      </tspan>
                      <tspan y="0">Y NOETIC </tspan>
                    </text>
                    <text
                      id="BLUE"
                      transform="translate(129.883 40.553)"
                      fill="#0b276f"
                      fontSize="8.87"
                      fontFamily="ZonaPro-Bold, Zona Pro"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0">
                        B
                      </tspan>
                      <tspan y="0" letterSpacing="-0.019em">
                        L
                      </tspan>
                      <tspan y="0">UE</tspan>
                    </text>
                    <text
                      id="MAPP"
                      transform="translate(75 26.927)"
                      fill="#0b276f"
                      fontSize="31.485"
                      fontFamily="ZonaPro-ExtraBold, Zona Pro"
                      fontWeight="800"
                    >
                      <tspan x="0" y="0">
                        MAPP
                      </tspan>
                    </text>
                  </g>
                </g>
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M20.593,0,0,35.667l4.478,7.757L20.593,15.513,36.708,43.424h0l4.477-7.753Z"
                  fill="#9400a0"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M41.684,0,33.345,14.444,37.823,22.2l3.861-6.687L57.8,43.424h0l4.478-7.753Z"
                  fill="#0b276f"
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M24.834,43.372l.03.052.029-.052Z"
                  fill="#231f20"
                />
              </g>
            </svg>
          </NavLink>
          <div className="hero">
            <h1 className="pt-40">
              Εκτοξεύστε την επισκεψιμότητα μέσα από την τεχνολογία!
            </h1>
            <p className="mt-5">
              Έχουμε τη λύση για μια συναρπαστική περιήγηση αυξάνοντας τη
              διαδραστικότητα και εμπλουτίζοντας τη μάθηση για τα εκθέματά σας.
            </p>
          </div>
        </div>
        <div className="relative w-full lg:w-1/2">
          <div className="absolute top-0 transform translate-x-1/2 right-1/2">
            <XSVG />
          </div>
          <div className="absolute top-32 transform translate-x-1/3 right-1/2">
            <MobileDevice />
          </div>
        </div>
      </div>
    </div>
  );
}
