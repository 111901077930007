const XSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="540"
    height="462"
    viewBox="0 0 540 462"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_102"
          data-name="Rectangle 102"
          width="540"
          height="462"
          transform="translate(713)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.216"
        y1="0.462"
        x2="1"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#9400a0" />
        <stop offset="1" stopColor="#082471" />
      </linearGradient>
    </defs>
    <g id="bg-full" transform="translate(-713)" clipPath="url(#clip-path)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M283.28,0,0,490.641,61.6,597.347,283.28,213.4,504.96,597.347h.028L566.574,490.7Z"
        transform="translate(1264.574 462) rotate(180)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
);

export default XSVG;
