export default function MobileDevice() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
      width="260"
      height="532"
      viewBox="0 0 260 532"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_97" data-name="Rectangle 97" width="57.832" height="57.833" />
        </clipPath>
      </defs>
      <g id="mob-hero" transform="translate(-828 -156)">
        <g
          id="Rectangle_98"
          data-name="Rectangle 98"
          transform="translate(828 156)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="6"
        >
          <rect width="260" height="532" rx="15" stroke="none" />
          <rect x="3" y="3" width="254" height="526" rx="12" fill="none" />
        </g>
        <path
          id="video"
          d="M5,0H249a5,5,0,0,1,5,5V250a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
          transform="translate(832 161)"
          fill="#dde6ff"
        />
        <g id="Group_74" data-name="Group 74" transform="translate(853.06 650.042)">
          <path
            id="Icon_awesome-home"
            data-name="Icon awesome-home"
            d="M10.456,5.335,3.58,11V17.11a.6.6,0,0,0,.6.6L8.356,17.7a.6.6,0,0,0,.594-.6V13.53a.6.6,0,0,1,.6-.6h2.387a.6.6,0,0,1,.6.6V17.1a.6.6,0,0,0,.6.6l4.177.012a.6.6,0,0,0,.6-.6V10.994L11.026,5.335a.455.455,0,0,0-.571,0Zm10.86,3.849L18.2,6.614V1.449A.448.448,0,0,0,17.751,1H15.663a.448.448,0,0,0-.448.448V4.157L11.876,1.41a1.79,1.79,0,0,0-2.275,0L.162,9.184a.448.448,0,0,0-.06.63l.951,1.156a.448.448,0,0,0,.631.061l8.772-7.225a.455.455,0,0,1,.571,0L19.8,11.031a.448.448,0,0,0,.63-.06l.951-1.156a.448.448,0,0,0-.063-.631Z"
            transform="translate(0 0.192)"
            fill="#9400a0"
          />
          <path
            id="Icon_awesome-qrcode"
            data-name="Icon awesome-qrcode"
            d="M0,8.16H7.16V1H0ZM2.387,3.387H4.773V5.773H2.387ZM9.547,1V8.16h7.16V1ZM14.32,5.773H11.933V3.387H14.32ZM0,17.707H7.16v-7.16H0Zm2.387-4.773H4.773V15.32H2.387Zm13.127-2.387h1.193V15.32h-3.58V14.127H11.933v3.58H9.547v-7.16h3.58V11.74h2.387Zm0,5.967h1.193v1.193H15.513Zm-2.387,0H14.32v1.193H13.127Z"
            transform="translate(51.314 0.193)"
            fill="#222"
          />
          <path
            id="Icon_awesome-folder-plus"
            data-name="Icon awesome-folder-plus"
            d="M17.3,4.387h-7.16L7.757,2H1.79A1.79,1.79,0,0,0,0,3.79V14.53a1.79,1.79,0,0,0,1.79,1.79H17.3a1.79,1.79,0,0,0,1.79-1.79V6.177A1.79,1.79,0,0,0,17.3,4.387Zm-3.58,6.265a.6.6,0,0,1-.6.6H10.442v2.685a.6.6,0,0,1-.6.6h-.6a.6.6,0,0,1-.6-.6V11.248H5.967a.6.6,0,0,1-.6-.6v-.6a.6.6,0,0,1,.6-.6H8.652V6.773a.6.6,0,0,1,.6-.6h.6a.6.6,0,0,1,.6.6V9.458h2.685a.6.6,0,0,1,.6.6Z"
            transform="translate(97.854 0.387)"
            fill="#222"
          />
          <path
            id="Icon_awesome-info"
            data-name="Icon awesome-info"
            d="M.746,15.82h.746V10.433H.746A.746.746,0,0,1,0,9.687V7.906A.746.746,0,0,1,.746,7.16H4.923a.746.746,0,0,1,.746.746V15.82h.746a.746.746,0,0,1,.746.746v1.781a.746.746,0,0,1-.746.746H.746A.746.746,0,0,1,0,18.348V16.566A.746.746,0,0,1,.746,15.82ZM3.58,0A2.685,2.685,0,1,0,6.265,2.685,2.685,2.685,0,0,0,3.58,0Z"
            transform="translate(146.78)"
            fill="#222"
          />
          <path
            id="Icon_awesome-user-cog"
            data-name="Icon awesome-user-cog"
            d="M22.767,13.921a4.375,4.375,0,0,0,0-1.589l.962-.556a.27.27,0,0,0,.123-.317,5.6,5.6,0,0,0-1.238-2.141.272.272,0,0,0-.336-.052l-.962.556a4.378,4.378,0,0,0-1.376-.794V7.917a.272.272,0,0,0-.213-.265,5.626,5.626,0,0,0-2.469,0,.272.272,0,0,0-.213.265V9.028a4.378,4.378,0,0,0-1.376.794l-.962-.556a.273.273,0,0,0-.336.052,5.6,5.6,0,0,0-1.238,2.141.275.275,0,0,0,.123.317l.962.556a4.375,4.375,0,0,0,0,1.589l-.962.556a.27.27,0,0,0-.123.317,5.624,5.624,0,0,0,1.238,2.141.272.272,0,0,0,.336.052l.962-.556a4.378,4.378,0,0,0,1.376.794v1.111a.272.272,0,0,0,.213.265,5.627,5.627,0,0,0,2.469,0,.272.272,0,0,0,.213-.265V17.225a4.378,4.378,0,0,0,1.376-.794l.962.556a.273.273,0,0,0,.336-.052,5.6,5.6,0,0,0,1.238-2.141.275.275,0,0,0-.123-.317ZM18.5,14.935a1.809,1.809,0,1,1,1.809-1.809A1.81,1.81,0,0,1,18.5,14.935ZM8.353,9.547A4.773,4.773,0,1,0,3.58,4.773,4.773,4.773,0,0,0,8.353,9.547Zm7.5,8.447c-.086-.045-.172-.1-.254-.145l-.295.172a1.463,1.463,0,0,1-1.809-.272,6.8,6.8,0,0,1-1.5-2.6,1.459,1.459,0,0,1,.668-1.7l.295-.172q-.006-.145,0-.291l-.295-.172a1.457,1.457,0,0,1-.668-1.7c.034-.108.082-.216.119-.324-.142-.011-.28-.045-.425-.045h-.623a6.492,6.492,0,0,1-5.437,0H5.012A5.013,5.013,0,0,0,0,15.752V17.3a1.79,1.79,0,0,0,1.79,1.79H14.917a1.788,1.788,0,0,0,1.014-.317,1.452,1.452,0,0,1-.075-.44Z"
            transform="translate(183.774)"
            fill="#222"
          />
        </g>
        <g id="Group_75" data-name="Group 75" transform="translate(903.18 246.86)">
          <path
            id="Polygon_1"
            data-name="Polygon 1"
            d="M23.5,0,47,40H0Z"
            transform="translate(3.82 39.14)"
            fill="#fff"
          />
          <path
            id="Polygon_2"
            data-name="Polygon 2"
            d="M33.5,0,67,55H0Z"
            transform="translate(42.82 24.14)"
            fill="#fff"
          />
          <ellipse
            id="Ellipse_36"
            data-name="Ellipse 36"
            cx="11"
            cy="10.5"
            rx="11"
            ry="10.5"
            transform="translate(-0.18 0.14)"
            fill="#fff"
          />
        </g>
        <g
          id="Rectangle_99"
          data-name="Rectangle 99"
          transform="translate(828 156)"
          fill="none"
          stroke="#d0d0d0"
          strokeWidth="6"
        >
          <rect width="260" height="532" rx="15" stroke="none" />
          <rect x="3" y="3" width="254" height="526" rx="12" fill="none" />
        </g>
        <line
          id="Line_2"
          data-name="Line 2"
          x2="248"
          transform="translate(834 640)"
          fill="none"
          stroke="#d0d0d0"
          strokeWidth="1"
        />
        <g id="Group_77" data-name="Group 77" transform="translate(33 153)">
          <g id="Group_73" data-name="Group 73" transform="translate(829.607 355.595)">
            <g id="Group_72" data-name="Group 72" clipPath="url(#clip-path)">
              <path
                id="Path_205"
                data-name="Path 205"
                d="M39.681,61.478q0-2.758,0-5.516l-.007.006h2.741V53.206H39.674l.007.007q0-1.38-.008-2.761H45.2V47.711h2.749v2.727H50.7v-5.5l-.007.007c.117,0,.233,0,.349,0l7.921,0v2.743H61.7V44.948H58.958V42.178H53.444V36.7H45.176v2.748H39.693v5.513h2.727v2.75H39.68v2.753H36.928V47.7h-8.26V44.954h8.246V22.909H39.68l-.007-.007q0,3.979,0,7.958v.294h2.752V25.671h2.748v5.5h2.768l-.007-.007V33.92h13.77v2.749h2.76v2.77l.007-.006H61.734v2.762h2.742l-.007-.007V47.7H67.23l-.006-.007v5.5H64.488v5.524H67.23l-.006-.006V61.47h5.5v2.749H64.478v5.508h-2.75V66.981H56.2l.007.007V64.235H53.464v-2.75h5.483V58.732H45.2v2.739H39.674ZM56.2,53.214V50.47H53.469v2.737h2.742v2.742H61.7V53.214Zm-5.518.013H47.961v2.726h2.726Zm5.531-13.808h2.726V36.693H56.218Z"
                transform="translate(-14.892 -11.897)"
              />
              <path
                id="Path_206"
                data-name="Path 206"
                d="M0,0H19.256V19.256H0ZM2.761,16.5H16.508V2.753H2.761Z"
              />
              <path
                id="Path_207"
                data-name="Path 207"
                d="M99.527,19.27H80.271V.014H99.527Zm-2.749-16.5H83.032V16.512H96.778Z"
                transform="translate(-41.699 -0.007)"
              />
              <path
                id="Path_208"
                data-name="Path 208"
                d="M.013,80.262H19.269V99.518H.013Zm16.5,2.761H2.77V96.77H16.516Z"
                transform="translate(-0.007 -41.694)"
              />
              <path
                id="Path_209"
                data-name="Path 209"
                d="M48.616,11.018V5.509H45.871V.012H59.618V5.5H56.9V8.255h2.726v5.5H56.881V11.018H54.13V8.272H51.38v2.739H48.61Zm2.763-5.53h2.726V2.762H51.379Z"
                transform="translate(-23.829 -0.006)"
              />
              <path
                id="Path_210"
                data-name="Path 210"
                d="M13.77,51.569v2.753h5.5v2.75H11.023v5.508H.017V57.1H2.745v2.721H8.268V51.569l-.007.006h5.515Z"
                transform="translate(-0.009 -26.789)"
              />
              <path
                id="Path_211"
                data-name="Path 211"
                d="M48.617,103.166v2.753h2.75v2.75h2.748v2.753H45.883v-8.25h2.741Z"
                transform="translate(-23.835 -53.592)"
              />
              <path
                id="Path_212"
                data-name="Path 212"
                d="M8.269,48.612H2.762v2.744H.019V45.877H8.262v2.742Z"
                transform="translate(-0.01 -23.832)"
              />
              <path
                id="Path_213"
                data-name="Path 213"
                d="M114.42,54.107h-2.733V48.617h-2.746V45.865h5.479Z"
                transform="translate(-56.592 -23.825)"
              />
              <path
                id="Path_214"
                data-name="Path 214"
                d="M28.644,48.618V45.875h5.492v2.736h-5.5l.007.007"
                transform="translate(-14.876 -23.831)"
              />
              <path
                id="Path_215"
                data-name="Path 215"
                d="M108.91,71.552V68.81h2.737v2.735H108.9Z"
                transform="translate(-56.572 -35.745)"
              />
              <rect
                id="Rectangle_91"
                data-name="Rectangle 91"
                width="2.726"
                height="2.73"
                transform="translate(55.103 38.569)"
              />
              <path
                id="Path_216"
                data-name="Path 216"
                d="M68.777,37.152V34.41h2.737v2.735H68.77Z"
                transform="translate(-35.724 -17.875)"
              />
              <path
                id="Path_217"
                data-name="Path 217"
                d="M48.621,88.722H45.879V85.974h2.742l-.007-.007v2.762Z"
                transform="translate(-23.833 -44.657)"
              />
              <path
                id="Path_218"
                data-name="Path 218"
                d="M108.91,94.485V91.742h2.737v2.736H108.9Z"
                transform="translate(-56.572 -47.658)"
              />
              <path
                id="Path_219"
                data-name="Path 219"
                d="M103.171,57.307h2.742v2.749h-2.742l.007.007V57.3Z"
                transform="translate(-53.594 -29.766)"
              />
              <rect
                id="Rectangle_92"
                data-name="Rectangle 92"
                width="2.727"
                height="2.726"
                transform="translate(33.062 52.352)"
              />
              <path
                id="Path_220"
                data-name="Path 220"
                d="M83.014,114.632v2.742H80.277v-2.735H83.02Z"
                transform="translate(-41.702 -59.548)"
              />
              <rect
                id="Rectangle_93"
                data-name="Rectangle 93"
                width="2.726"
                height="2.726"
                transform="translate(55.102 55.107)"
              />
              <path
                id="Path_221"
                data-name="Path 221"
                d="M71.556,65.81H68.814V63.073h2.735v2.743l.007-.007"
                transform="translate(-35.747 -32.765)"
              />
              <rect
                id="Rectangle_94"
                data-name="Rectangle 94"
                width="8.237"
                height="8.236"
                transform="translate(5.512 5.516)"
              />
              <rect
                id="Rectangle_95"
                data-name="Rectangle 95"
                width="8.236"
                height="8.236"
                transform="translate(44.08 5.51)"
              />
              <rect
                id="Rectangle_96"
                data-name="Rectangle 96"
                width="8.236"
                height="8.236"
                transform="translate(5.51 44.08)"
              />
            </g>
          </g>
          <text
            id="Ξενάγηση"
            transform="translate(834 426)"
            fill="#222"
            fontSize="10"
            fontFamily="ZonaPro, Zona Pro"
          >
            <tspan x="0" y="0">
              Ξενάγηση
            </tspan>
          </text>
        </g>
        <g id="Group_78" data-name="Group 78" transform="translate(33 153)">
          <g id="Group_76" data-name="Group 76" transform="translate(951.051 355.595)">
            <g id="Group_72-2" data-name="Group 72" clipPath="url(#clip-path)">
              <path
                id="Path_205-2"
                data-name="Path 205"
                d="M39.681,61.478q0-2.758,0-5.516l-.007.006h2.741V53.206H39.674l.007.007q0-1.38-.008-2.761H45.2V47.711h2.749v2.727H50.7v-5.5l-.007.007c.117,0,.233,0,.349,0l7.921,0v2.743H61.7V44.948H58.958V42.178H53.444V36.7H45.176v2.748H39.693v5.513h2.727v2.75H39.68v2.753H36.928V47.7h-8.26V44.954h8.246V22.909H39.68l-.007-.007q0,3.979,0,7.958v.294h2.752V25.671h2.748v5.5h2.768l-.007-.007V33.92h13.77v2.749h2.76v2.77l.007-.006H61.734v2.762h2.742l-.007-.007V47.7H67.23l-.006-.007v5.5H64.488v5.524H67.23l-.006-.006V61.47h5.5v2.749H64.478v5.508h-2.75V66.981H56.2l.007.007V64.235H53.464v-2.75h5.483V58.732H45.2v2.739H39.674ZM56.2,53.214V50.47H53.469v2.737h2.742v2.742H61.7V53.214Zm-5.518.013H47.961v2.726h2.726Zm5.531-13.808h2.726V36.693H56.218Z"
                transform="translate(-14.892 -11.897)"
              />
              <path
                id="Path_206-2"
                data-name="Path 206"
                d="M0,0H19.256V19.256H0ZM2.761,16.5H16.508V2.753H2.761Z"
              />
              <path
                id="Path_207-2"
                data-name="Path 207"
                d="M99.527,19.27H80.271V.014H99.527Zm-2.749-16.5H83.032V16.512H96.778Z"
                transform="translate(-41.699 -0.007)"
              />
              <path
                id="Path_208-2"
                data-name="Path 208"
                d="M.013,80.262H19.269V99.518H.013Zm16.5,2.761H2.77V96.77H16.516Z"
                transform="translate(-0.007 -41.694)"
              />
              <path
                id="Path_209-2"
                data-name="Path 209"
                d="M48.616,11.018V5.509H45.871V.012H59.618V5.5H56.9V8.255h2.726v5.5H56.881V11.018H54.13V8.272H51.38v2.739H48.61Zm2.763-5.53h2.726V2.762H51.379Z"
                transform="translate(-23.829 -0.006)"
              />
              <path
                id="Path_210-2"
                data-name="Path 210"
                d="M13.77,51.569v2.753h5.5v2.75H11.023v5.508H.017V57.1H2.745v2.721H8.268V51.569l-.007.006h5.515Z"
                transform="translate(-0.009 -26.789)"
              />
              <path
                id="Path_211-2"
                data-name="Path 211"
                d="M48.617,103.166v2.753h2.75v2.75h2.748v2.753H45.883v-8.25h2.741Z"
                transform="translate(-23.835 -53.592)"
              />
              <path
                id="Path_212-2"
                data-name="Path 212"
                d="M8.269,48.612H2.762v2.744H.019V45.877H8.262v2.742Z"
                transform="translate(-0.01 -23.832)"
              />
              <path
                id="Path_213-2"
                data-name="Path 213"
                d="M114.42,54.107h-2.733V48.617h-2.746V45.865h5.479Z"
                transform="translate(-56.592 -23.825)"
              />
              <path
                id="Path_214-2"
                data-name="Path 214"
                d="M28.644,48.618V45.875h5.492v2.736h-5.5l.007.007"
                transform="translate(-14.876 -23.831)"
              />
              <path
                id="Path_215-2"
                data-name="Path 215"
                d="M108.91,71.552V68.81h2.737v2.735H108.9Z"
                transform="translate(-56.572 -35.745)"
              />
              <rect
                id="Rectangle_91-2"
                data-name="Rectangle 91"
                width="2.726"
                height="2.73"
                transform="translate(55.103 38.569)"
              />
              <path
                id="Path_216-2"
                data-name="Path 216"
                d="M68.777,37.152V34.41h2.737v2.735H68.77Z"
                transform="translate(-35.724 -17.875)"
              />
              <path
                id="Path_217-2"
                data-name="Path 217"
                d="M48.621,88.722H45.879V85.974h2.742l-.007-.007v2.762Z"
                transform="translate(-23.833 -44.657)"
              />
              <path
                id="Path_218-2"
                data-name="Path 218"
                d="M108.91,94.485V91.742h2.737v2.736H108.9Z"
                transform="translate(-56.572 -47.658)"
              />
              <path
                id="Path_219-2"
                data-name="Path 219"
                d="M103.171,57.307h2.742v2.749h-2.742l.007.007V57.3Z"
                transform="translate(-53.594 -29.766)"
              />
              <rect
                id="Rectangle_92-2"
                data-name="Rectangle 92"
                width="2.727"
                height="2.726"
                transform="translate(33.062 52.352)"
              />
              <path
                id="Path_220-2"
                data-name="Path 220"
                d="M83.014,114.632v2.742H80.277v-2.735H83.02Z"
                transform="translate(-41.702 -59.548)"
              />
              <rect
                id="Rectangle_93-2"
                data-name="Rectangle 93"
                width="2.726"
                height="2.726"
                transform="translate(55.102 55.107)"
              />
              <path
                id="Path_221-2"
                data-name="Path 221"
                d="M71.556,65.81H68.814V63.073h2.735v2.743l.007-.007"
                transform="translate(-35.747 -32.765)"
              />
              <rect
                id="Rectangle_94-2"
                data-name="Rectangle 94"
                width="8.237"
                height="8.236"
                transform="translate(5.512 5.516)"
              />
              <rect
                id="Rectangle_95-2"
                data-name="Rectangle 95"
                width="8.236"
                height="8.236"
                transform="translate(44.08 5.51)"
              />
              <rect
                id="Rectangle_96-2"
                data-name="Rectangle 96"
                width="8.236"
                height="8.236"
                transform="translate(5.51 44.08)"
              />
            </g>
          </g>
          <text
            id="Ενδιαφέροντα_αντικείμενα"
            data-name="Ενδιαφέροντα αντικείμενα"
            transform="translate(981 426)"
            fill="#222"
            fontSize="10"
            fontFamily="ZonaPro, Zona Pro"
          >
            <tspan x="-36" y="0">
              Ενδιαφέροντα
            </tspan>
            <tspan x="-29" y="12">
              αντικείμενα
            </tspan>
          </text>
        </g>
        <text
          id="Καλώς_ήλθατε"
          data-name="Καλώς ήλθατε"
          transform="translate(853 450)"
          fill="#9400a0"
          fontSize="20"
          fontFamily="ZonaPro-ExtraBold, Zona Pro"
          fontWeight="800"
        >
          <tspan x="-73" y="18">
            Καλώς ήλθατε
          </tspan>
        </text>
      </g>
    </svg>
  );
}
